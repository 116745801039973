<template>
  <div class="d-flex flex-nowrap">
    <document-ref-icon
      :visible="showIcon"
      icon-name="i.WorkQueues"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <div class="d-flex flex-column flex-nowrap">
      <span class="nowrap">{{name}}</span>
      <slot name="doc-type" />

      <!-- <user-ref
      v-if="mainAgentId && _mainAgentProps.visible"
      hide-email
      hide-phone
      hide-group
      class="mt-1"
      :avatar-props="{xsmall: true}"
      :id="mainAgentId"
      block-popup
    /> -->
    </div>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon')

  },
  computed: {
    // mainAgentId () {
    //   return this.cache?.doc?.mainAgentId
    // },
    name () {
      return this.id ? this.cache?.doc?.name : this.$t('t.None')
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
    // _mainAgentProps () {
    //   const _ = Object.assign({
    //     visible: true,
    //     small: true
    //   }, this.mainAgentProps)

    //   return _
    // }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.WorkQueueRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    mixed: Boolean,
    // mainAgentProps: Object,
    iconProps: Object,

    showIcon: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.smallText
  font-size 0.75em
</style>
